import type { FormEvent } from 'react'
import { useState } from 'react'
import { LoadingIcon } from 'src/components/Icons/LoadingIcon'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { ErrorIcon } from 'src/components/Icons/ErrorIcon'

import { CartCouponSkeleton } from './CartCouponSkeleton'
import GiftcardInfoModal from './GiftcardInfoModal'

export const CartCoupon = () => {
  const { addCoupon, isLoading: orderFormIsLoading } = useCheckout()
  const { sendToast } = useToastContext()
  const [inputValue, setInputValue] = useState('')
  const [isCouponInvalid, setIsCouponInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCouponApplied, setIsCouponApplied] = useState(false)
  const [isGiftcardModalOpen, setIsGiftcardModalOpen] = useState(false)

  const handleApplyCoupon = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!inputValue?.trim()) {
      setIsCouponInvalid(true)

      return
    }

    setIsLoading(true)
    setIsCouponInvalid(false)

    const orderForm = await addCoupon(inputValue)
    const isBemVindoCoupon = inputValue.toLowerCase() === 'bemvindo'

    const invalidCouponMessage = orderForm.messages.find(
      (message) =>
        message.code === 'couponNotFound' || message.code === 'couponExpired'
    )

    const haveInvalidCouponMessage =
      invalidCouponMessage !== undefined || isBemVindoCoupon

    if (isBemVindoCoupon) {
      sendToast({
        text: 'O cupom “BEMVINDO” é válido apenas para a sua primeira compra via aplicativo Decathlon.',
        variant: 'error',
      })
    }

    setIsCouponInvalid(haveInvalidCouponMessage)

    setIsCouponApplied(!haveInvalidCouponMessage)

    if (!haveInvalidCouponMessage) {
      sendToast({
        text: 'Cupom aplicado',
        variant: 'success',
      })
    }

    setIsLoading(false)
  }

  const handleRemoveCoupon = async () => {
    await addCoupon('')

    setIsCouponApplied(false)
  }

  const handleInputBlur = () => {
    const isOnlyNumbers = /^\d+$/
    const isValeTroca = isOnlyNumbers.test(inputValue)

    if (inputValue.length !== 19 || !isValeTroca) {
      return
    }

    setIsGiftcardModalOpen(true)
  }

  if (orderFormIsLoading) {
    return <CartCouponSkeleton />
  }

  if (isCouponApplied) {
    return (
      <div className="font-inter mb-6 flex justify-between p-5 bg-offWhite text-restructure-tertiary">
        <div className="flex flex-col gap-2">
          <span className="text-sm font-semibold">Cupom aplicado</span>
          <span className="text-xs">{inputValue}</span>
        </div>
        <button className="text-sm underline" onClick={handleRemoveCoupon}>
          Excluir
        </button>
      </div>
    )
  }

  return (
    <>
      <div className="font-inter flex flex-col mb-6">
        <span className="text-sm">Cupom de desconto</span>
        <div className="flex flex-col gap-2 mt-2 mb-3">
          <form className="flex gap-3" onSubmit={handleApplyCoupon}>
            <input
              type="text"
              onChange={(e) => {
                setInputValue(e.target.value)
                setIsCouponInvalid(false)
              }}
              onBlur={() => {
                handleInputBlur()
              }}
              className={`${
                isCouponInvalid
                  ? 'border-restructure-border-error'
                  : 'border-lightGray focus-visible:border-restructure-background-action-1'
              } ${
                isLoading && 'opacity-30'
              } outline-none h-12 border flex-1 px-4 py-3`}
            />
            <button
              className="h-12 w-[121px] border border-lightGray font-semibold"
              type="submit"
            >
              {isLoading ? <LoadingIcon /> : 'Aplicar'}
            </button>
          </form>
          {isCouponInvalid && (
            <span className="flex items-center gap-1 text-restructure-error text-sm">
              <ErrorIcon color="#AB0009" className="mb-1" />
              Código inválido
            </span>
          )}
        </div>

        <p className="text-xs text-tertiary">
          <b>Descontos:</b> são aplicados apenas para produtos Decathlon. <br />
          <b>Vale-compra ou cartão presente:</b> Devem ser inseridos na etapa de
          pagamento.
        </p>
      </div>
      {isGiftcardModalOpen && (
        <GiftcardInfoModal setIsGiftcardModalOpen={setIsGiftcardModalOpen} />
      )}
    </>
  )
}
